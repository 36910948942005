<template>
  <!-- 文件预览组件 预览文件组件-->
  <span class="fileViewBox">
    <!-- 使用案例 -->
    <!-- <JxFileViewBox DialogTitle="学历证明" :fileTitle="files_item.name" :fileTime="files_item.create_time" :fileSize="files_item.size"
      :fileDownload="files_item.url" :viewSrc="files_item.view_url" :fileExt="files_item.ext" :itemData="files_item">
      <span class="ellipsis_1"> {{files_item.name}} </span>
    </JxFileViewBox> -->

    <div @click="handlerOpen()">
      <slot></slot>
    </div>

    <!-- 文档 -->
    <PublicBoxMaskDialog v-if="showFileView" :DialogTitle="DialogTitle" :DialogShow="showFileView" @DialogCloseEvente="closeDialogEvent"
      DialogWidth="1400px">
      <template slot="contentMinBox">
        <div class="file_info_div">
          <div class="file_info_left">
            <p>{{file_Title || "-"}}</p>
            <span>文件大小: {{ file_Size || "-" }}</span>
            <span>上传时间: {{ file_Time || "-" }}</span>
          </div>
          <div class="file_info_right">
            <div class="btn_down" @click="handleDownload()">下载</div>
            <el-button :disabled="file_index+1==1" class="btn_page" :class="{ 'active':file_index+1==1 }" style="margin-left: 60px;"
              @click="handlePage(2)">上一份
            </el-button>
            <div class="btn_text">{{file_index +1}}/{{fileArr.length}}</div>
            <el-button :disabled="file_index+1==fileArr.length" class="btn_page" :class="{ 'active':file_index+1==fileArr.length }"
              @click="handlePage(1)">下一份</el-button>
          </div>
        </div>

        <div class="page_box">
          <!-- 文档 -->
          <template v-if="doc_DialogShow">
            <div class="outside_div" v-loading="iframe_loading" element-loading-text="资源加载中" element-loading-background="rgba(0, 0, 0, 0.5)">
              <iframe :src="view_Src" width="100%" height="100%" seamless frameborder="0" @load="loadFrameEvent" @error="loadFrameEvent"></iframe>
            </div>
          </template>

          <!-- 图片 -->
          <template v-if="image_DialogShow">
            <el-image class="outside_div" :src="view_Src" fit="contain" @load="loadFrameEvent" @error="loadFrameEvent" v-loading="iframe_loading"
              element-loading-text="资源加载中" element-loading-background="rgba(0, 0, 0, 0.5)">>
            </el-image>
          </template>

          <!-- 视频 -->
          <template v-if="video_DialogShow">
            <div class="outside_div">
              <video :src="view_Src" type="video/mp4" poster="false.png" autoplay controls loop
                style="width: 100%; height: 100%; border: 1px solid #ebeef5;">
              </video>
            </div>
          </template>

          <template v-if="Audio_DialogShow">
            <audio controls="controls" controlsList="nodownload" oncontextmenu="return false" class="outside_yinpin">
              <source :src="view_Src" type="audio/mp3" />
              <!-- 您的浏览器不支持 audio 标签。 -->
            </audio>
          </template>

          <CommonEmpty v-if="emtpy_box" hVal="200px" TextVal="暂不支持查看该类型文件，请下载后观看"></CommonEmpty>

        </div>

      </template>
    </PublicBoxMaskDialog>

  </span>
</template>

<script>
import { deepClone, fileSizeFilter } from "@/utils/utils.js";
export default {
  props: {
    // 当前文件索引
    fileIndex: {
      type: [String, Number],
      default: 0,
    },
    // 文件列表
    fileArr: {
      type: Array,
      default: [],
    },
    // 文档标题
    DialogTitle: {
      type: String,
      default: '标题',
    },
  },
  data() {
    return {
      // 音频
      Audio_DialogShow: false,
      // 视频
      video_DialogShow: false,
      // 图片
      image_DialogShow: false,
      // 文档
      doc_DialogShow: false,
      iframe_loading: false,
      // 空
      emtpy_box: false,
      // 弹框
      showFileView: false,

      current_item: {},
      file_arr: [],
      file_index: 0,

      file_Title: "",
      file_Time: "",
      file_Size: "",
      file_Download: "",
      view_Src: "",
      file_Ext: "",
    };
  },
  methods: {
    // 下载
    handleDownload() {
      if (this.file_Download) {
        window.open(this.file_Download);
      }
    },
    // 关闭预览弹框
    closeDialogEvent(num) {
      if (num != 1) {
        this.showFileView = false;
        this.file_index = this.fileIndex;
      };
      this.Audio_DialogShow = false;
      this.video_DialogShow = false;
      this.image_DialogShow = false;
      this.doc_DialogShow = false;
      this.iframe_loading = false;
      this.emtpy_box = false;
    },
    // iframe加载完成
    loadFrameEvent() {
      this.iframe_loading = false;
    },
    // 打开
    handlerOpen() {
      this.closeDialogEvent(1);
      this.$nextTick(() => {
        this.handler_type();
      });
    },
    // 获取值
    handler_type() {
      this.current_item = this.fileArr[this.file_index] || {};
      let current_item = deepClone(this.current_item);
      let file_Size = current_item.size;
      if (/^\d+$/.test(file_Size)) {
        file_Size = fileSizeFilter(file_Size);
      };
      this.file_Size = file_Size;
      this.file_Title = current_item.val || current_item.name;
      this.file_Time = current_item.create_time;
      this.file_Download = current_item.url;
      this.view_Src = current_item.view_url;
      this.file_Ext = current_item.ext;

      if (!this.view_Src) {
        this.$message({
          message: 'view_url 预览链接为空，无法预览',
          type: 'error'
        });
        return false;
      };

      let ext = this.file_Ext;
      if (this.isTypeImage(ext)) {
        this.image_DialogShow = true;
        this.iframe_loading = true;

      } else if (this.isTypeDoc(ext)) {
        this.doc_DialogShow = true;
        this.iframe_loading = true;

      } else if (this.isTypeVideo(ext)) {
        this.video_DialogShow = true;

      } else if (this.isTypeAudio(ext)) {
        this.Audio_DialogShow = true;

      } else {
        this.emtpy_box = true;
      };
      this.showFileView = true;
    },
    //判断是否图片
    isTypeImage(ext) {
      return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
    },
    // 判断是否是文档
    isTypeDoc(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].includes(ext.toLowerCase());
    },
    // 判断是否是视频
    isTypeVideo(ext) {
      return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
    },
    // 是否 音频
    isTypeAudio(ext) {
      return ["mp3", "wav", "wma", "flac", "midi", "ra", "ape", "aac", "cda"].includes(ext.toLowerCase());
    },
    // 上一份下一份
    handlePage(num) {
      if (num === 1) {
        this.file_index = this.file_index + 1;
        if (this.file_index >= this.fileArr.length - 1) {
          this.file_index = this.fileArr.length - 1;
        };
      };
      if (num === 2) {
        this.file_index = this.file_index - 1;
        if (this.file_index <= 0) {
          this.file_index = 0;
        }
      };
      this.closeDialogEvent(1);
      this.$nextTick(() => {
        this.handler_type();
      });
    },
  },
  mounted() {
    this.file_index = this.fileIndex;
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-top: 0px !important;
}
.fileViewBox {
  display: inline-block;
}
.file_info_div {
  height: 88px;
  display: flex;
  align-items: center;
  .file_info_left {
    flex: 1;
    P {
      font-size: 18px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      margin-bottom: 6px;
    }
    span {
      font-size: 16px;
      font-weight: normal;
      color: rgba(102, 102, 102, 1);
      margin-right: 30px;
    }
  }
  .file_info_right {
    display: flex;
    align-items: center;
    .btn_down {
      width: 100px;
      height: 40px;
      line-height: 40px;
      background: rgba(255, 136, 9, 1);
      border-radius: 10px;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      cursor: pointer;
    }
    .btn_page {
      padding: 0;
      margin: 0;
      width: 70px;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(204, 204, 204, 1);
      background-color: #fff;
      border-radius: 10px;
      font-size: 16px;
      cursor: pointer;
      color: #333;
      box-sizing: border-box;
      &.active {
        background-color: #8fa0af;
        color: #fff;
        cursor: no-drop;
      }
    }
    .btn_text {
      width: 60px;
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
    }
  }
}
.page_box {
  width: 100%;
  height: calc(100% - 90px);
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
}
.outside_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>