<template>
  <!-- 工作室各指标考核 -->
  <div class="CheckTarget" id="CheckTarget">

    <div v-if="stepArr.length" class="step_div_box">
      <JxStepBar :stepArr="stepArr" :currentIndex="current_step"></JxStepBar>
      <div>
        <el-button class="option_box_btn" @click="upDaochu" v-if="examineLi.examine >= 1">材料导出</el-button>
      </div>
    </div>

    <!-- 表格数据 -->
    <div class="table_div_box" v-loading="listLoading">
      <div class="table_box">
        <div class="table_title_div">
          <div class="table_title_item table_1_div_item">一级指标</div>
          <div class="table_title_box">
            <div class="table_title_item table_2_div_item">二级指标</div>
            <div class="table_title_item table_3_div_item">考核要点</div>
            <div class="table_title_item table_5_div_item">佐证材料</div>
            <div class="table_title_item table_6_div_item">总分</div>
            <div v-if="detailsInfo.is_show_self" class="table_title_item table_4_div_item">
              自评分占比<br>({{ detailsInfo.self_scale }})</div>
          </div>
        </div>

        <el-form ref="FormTempData" :model="FormTempData" :rules="rules1" label-position="right" label-width="0px"
          style="width: auto; margin-left: 0px;" class="">
          <div v-if="tableData.length" class="table_content_box">
            <div v-for="(item_1, index_1) in tableData" :key="index_1 + 'a'" class="table_content_item">
              <div class="table_1_children table_1_div_item">
                <div class="table_title_1_span">{{ item_1.title }}</div>
              </div>
              <div class="table_2_box_div">
                <div class="table_2_children" v-for="(item_2, index_2) in item_1.children" :key="index_2 + 'b'">

                  <div class="table_2_item table_2_div_item">
                    <span class="table_title_2_span">{{ item_2.title }}</span>
                  </div>

                  <div class="table_2_item table_3_div_item">
                    <span class="table_title_3_span">{{ item_2.examine }}</span>
                  </div>

                  <div class="table_2_item table_5_div_item">
                    <el-form-item label="" prop="name" class="table_input_div table_files_div">
                      <div class="proof_item_proof" :class="{ 'active': index_3 == (item_2.proof_list.length - 1) }"
                        v-for="(item_3, index_3) in item_2.proof_list" :key="index_3 + 'c'">

                        <!-- <input class="proof_item_input_div" type="text" :data-pid="item_2.id" value="" /> -->
                        <!-- 上传材料 -->
                        <div v-if="item_3.cate == 1" class="proof_item_file">
                          <div class="proof_item_add_div">
                            <div class="proof_item_add_name">*{{ item_3.description }} </div>
                            <div v-if="[1, 2].includes(currentStatus) && isUpOk" class="upload_div_box">
                              <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                                :dcdom="'ceshi99b' + index_3 + '_c_' + index_2 + 'd_123_' + index_1" :dcConfig="dcConfig"
                                @dc-success="dcFile_1($event, item_3)" @dc-progress="dcProgressTrue = false"
                                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.gif,.jpg,.jpeg,.png,"
                                :filesSizeMax="50">
                                <div slot="trigger" class="proof_item_add_btn">上传+</div>
                              </dc-upload>
                            </div>
                          </div>
                          <div class="proof_item_add_list">
                            <div v-for="(files_item, files_index) in item_3.file_arr || []" class="files_item"
                              :key="files_index">
                              <JxFileViewBox :fileArr="item_3.file_arr" :fileIndex="files_index"
                                :DialogTitle="item_3.description">
                                <span class="files_name_item ellipsis_1">{{ files_item.val || "-" }} </span>
                              </JxFileViewBox>
                              <div v-if="[1, 2].includes(currentStatus) && isUpOk" class="del_btn"
                                @click="handleDelete(item_3, files_index)">删除</div>
                            </div>
                          </div>
                        </div>
                        <!-- 系统指标 -->
                        <div v-if="item_3.cate == 2" class="proof_item_html">*{{ item_3.description }} </div>
                        <!-- 录入数据 -->
                        <div v-if="item_3.cate == 3" class="proof_item_input_box" v-html="item_3.description"></div>
                        <div v-if="item_3.cate == 4" class="textbox">
                          <p class="proof_item_text_box" v-html="item_3.description"></p>
                          <el-button type="text" @click="openBianJi(item_1, item_2, item_3)">编辑</el-button>
                        </div>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="table_2_item table_6_div_item">
                    <span class="table_title_2_span">{{ item_2.score_val }}</span>
                  </div>

                  <div v-if="detailsInfo.is_show_self" class="table_2_item table_4_div_item">
                    <el-form-item v-if="[1, 2].includes(currentStatus)" label="" prop="name" class="table_input_div">
                      <el-input-number size="mini" v-model="item_2.self_score" :precision="1" :step="0.1"
                        :step-strictly="true" :min="0" :max="Number(item_2.score_val)">
                      </el-input-number>
                    </el-form-item>
                    <span v-else class="table_title_2_span">{{ item_2.self_score || 0 }}</span>
                  </div>

                </div>
              </div>
            </div>

            <div class="table_content_item">
              <div class="table_1_children table_1_div_item">
                <el-form-item label="" prop="name" class="table_input_div">
                  <span style="font-weight: bold;">总分</span>
                </el-form-item>
              </div>
              <div class="table_2_box_div">
                <div class="table_2_children">
                  <div class="table_2_item table_2_div_item">
                    <el-form-item label="" prop="name" class="table_input_div">
                    </el-form-item>
                  </div>
                  <div class="table_2_item table_3_div_item">
                    <el-form-item label="" prop="name" class="table_input_div">
                    </el-form-item>
                  </div>
                  <div class="table_2_item table_5_div_item">
                    <el-form-item label="" prop="name" class="table_input_div">
                    </el-form-item>
                  </div>
                  <div class="table_2_item table_6_div_item">
                    <el-form-item label="" prop="name" class="table_input_div">
                      <span style="font-weight: bold;">{{ theSum }}</span>
                    </el-form-item>
                  </div>
                  <div v-if="detailsInfo.is_show_self" class="table_2_item table_4_div_item">
                    <el-form-item label="" prop="name" class="table_input_div">
                      <span style="font-weight: bold;">{{ selfSum }}</span>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="false" class="table_content_item">
              <div class="table_1_children table_1_div_item">
                <el-form-item label="" prop="name" class="table_input_div">
                  <span style="font-weight: bold;"> 佐证材料 <br> 上传说明</span>
                </el-form-item>
              </div>
              <div class="table_2_box_div">
                <div class="table_2_children table_2_add_box" style="min-height:91px;">
                  <el-form-item label="" prop="name" class="table_3_add_box">
                    <el-input v-model="detailsInfo.instruction" type="textarea" :rows="3" placeholder="请输入内容"> </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>

      </div>
      <el-empty v-if="!tableData.length && !listLoading" description="暂无数据"></el-empty>
    </div>

    <div v-if="[1, 2].includes(currentStatus)" class="tips_msg_text" style="color:#666666">
      注：提交考核材料前，请先完善“工作室基本信息”表格内容，提交后不可修改。佐证材料上传支持Word/PDF/JPG/PNG等格式，每份材料不能超过50M。</div>

    <div v-if="[1, 2].includes(currentStatus)" class="optiondiv_box">
      <el-button :loading="save_btn_1_loading" class="optiondiv_btn" type="primary"
        @click="handleSaveNext(1)">保存</el-button>
      <el-button v-if="currentStatus == 1" disabled class="optiondiv_btn optiondiv_2_btn" type="primary">{{
        current_2_Status }}</el-button>
      <el-button v-if="currentStatus == 2" class="optiondiv_btn optiondiv_1_btn" type="primary"
        :loading="save_btn_2_loading" @click="handleSubmit(2)">
        {{ current_2_Status }}
      </el-button>
    </div>

    <div v-if="check_log.length" class="time_line_div">
      <el-timeline>
        <template v-for="(item, index) in check_log">
          <!-- 自评 -->
          <el-timeline-item v-if="item.examine == 1" color="#148dfb" :key="index + 'a'">
            <div class="time_line_time">
              <div class="time_line_p">
                <span class="time_span_1">{{ item.date }}</span>
                <span v-if="detailsInfo.self_scale == '0%'" class="time_span_2">{{ item.examine_str || "-" }}</span>
                <template v-else>
                  <span class="time_span_2">{{ item.examine_str || "-" }}</span>
                  <span class="time_span_6">（自评:{{ item.score || "-" }}分）</span>
                  <span class="time_span_2">。</span>
                </template>
              </div>
            </div>
          </el-timeline-item>
          <!-- 初审 复审 -->
          <el-timeline-item v-if="item.examine == 2 || item.examine == 3" color="#148dfb" :key="index + 'b'">
            <div class="time_line_time">
              <div class="time_line_p">
                <span class="time_span_1">{{ item.date }}</span>
                <span class="time_span_2">{{ item.examine_str || "-" }}</span>
                <!-- <span class="time_span_3">（{{ item.org_name || "-" }}）</span> -->
                <span class="time_span_2">:</span>
                <span v-if="item.examine_status == 1" class="time_span_4">{{ item.examine_status_str }}</span>
                <span v-if="item.examine_status == 2" class="time_span_5">{{ item.examine_status_str }}</span>
              </div>
              <div class="time_line_remark">评语：{{ item.comment || "暂无" }} </div>
            </div>
          </el-timeline-item>
          <!-- 专家 -->
          <el-timeline-item v-if="item.examine == 4" color="#148dfb" :key="index + 'c'">
            <div class="time_line_time">
              <div class="time_line_p">
                <span class="time_span_1">{{ item.date }}</span>
                <span class="time_span_2">专家</span>
                <span class="time_span_3">（{{ item.add_user_name || "-" }}）</span>
                <span class="time_span_2">已提交评分</span>
              </div>
              <div class="time_line_remark">评语：{{ item.comment || "暂无" }} </div>
            </div>
          </el-timeline-item>
          <!-- 结果复核 -->
          <el-timeline-item v-if="item.examine == 5" color="#148dfb" :key="index + 'd'">
            <div class="time_line_time">
              <div class="time_line_p">
                <span class="time_span_1">{{ item.date }}</span>
                <span class="time_span_2">{{ item.examine_str || "-" }}</span>
                <!-- <span class="time_span_3">（{{ item.org_name || "-" }}）</span> -->
                <span class="time_span_2">最终评级为 </span>
                <span class="time_span_5"> "{{ item.score_level || "-" }}"</span>
              </div>
              <div class="time_line_remark">评语：{{ item.comment || "暂无" }} </div>
            </div>
          </el-timeline-item>
          <!-- 结束 -->
          <el-timeline-item v-if="item.examine == 6" color="#148dfb" :key="index + 'e'">
            <div class="time_line_time">
              <div class="time_line_p">
                <!-- <span class="time_span_2">最终得分：</span> -->
                <!-- <span class="time_span_6">{{item.score || "-"}}分，</span> -->
                <span class="time_span_2">最终评级为 </span>
                <span class="time_span_5"> "{{ item.score_level || "-" }}"</span>
              </div>
            </div>
          </el-timeline-item>
          <!-- 综合得分 -->
          <el-timeline-item v-if="item.examine == 7" color="#148dfb" :key="index + 'f'">
            <div class="time_line_time">
              <div class="time_line_p">
                <span class="time_span_1">{{ item.date }}</span>
                <!-- <span class="time_span_2">综合得分：</span>
                <span class="time_span_6">{{item.score || "-"}}分，</span> -->
                <!-- <span class="time_span_2">初步评级为 </span> -->
                <span class="time_span_5" style="margin-left: 0px;">{{ item.str || "-" }}</span>
              </div>
            </div>
          </el-timeline-item>
        </template>
      </el-timeline>
      <!-- <CommonEmpty v-if="!check_log.length" hVal="200px" TextVal="暂无审核日志数据"></CommonEmpty> -->
    </div>
    <el-dialog title="编辑" :visible.sync="dialogcontents" width="1000px" :before-close="closecontents">
      <!-- <vue-ueditor-wrap v-model="contents" :config="config"></vue-ueditor-wrap> -->
      <el-input type="textarea" :autosize="{ minRows: 20, maxRows: 20 }" v-model="contents">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogcontents = false">取 消</el-button>
        <el-button type="primary" @click="addcontents">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { debounce, throttle } from "@/utils/utils.js";
import { deepClone, isObject, isPureNumber } from "@/utils/utils.js";
import * as researchApi from '@/api/researchApi.js';
import JxFileViewBox from "./jx_fileView_box.vue";
import JxStepBar from "./jx_step_bar.vue";
import mathjs from "@/utils/mathjs.js";

export default {
  name: "CheckTarget",
  props: {
    examineLi: {
      type: Object,
      default: {}
    }
  },
  data() {
    const validate_1 = (rule, value, callback) => {
      if (!this.education_files.length) {
        callback(new Error('请至少选择一个材料文件'));
      } else {
        callback();
      };
    };
    const validate_2 = (rule, value, callback) => {
      if (!this.job_files.length) {
        callback(new Error('请至少选择一个材料文件'));
      } else {
        callback();
      };
    };
    const validate_3 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('年龄不能为空'));
      };
      if (value > 200 || value < 18) {
        callback(new Error('18-100区间范围'));
      } else {
        callback();
      }
    };
    return {
      dataId: "",
      dialogcontents: false,
      contents: '',
      config: {
        zIndex: 9999,//有层级问题可以设置zIndex
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 420, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        UEDITOR_HOME_URL: "/UEditor/",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: `/api/upload/bdUpload`, //后端配置的上传接口（接口返回配置只需要一个oobj，不需要外层内容）
        toolbars: [[
          // 'fullscreen', 'undo', 'redo', '|',
          'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
          'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
          'customstyle', 'paragraph', 'fontfamily', 'fontsize',
        ]],
      },
      save_btn_1_loading: false, // 保存按钮loading
      save_btn_2_loading: false, // 保存按钮loading
      //金山云上传配置
      isUpOk: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      FormTempData: {
        teaching_studio_id: "",
        teaching_studio_appraisal_id: "",
        education: "",
        major: "",
        age: "",
        ranks: "",
        education_file_ids: "",
        ranks_file_ids: "",
      },
      rules1: {
        education: [{ required: true, message: '请选择学历', trigger: 'change' }],
        ranks: [{ required: true, message: '请选择职称', trigger: 'change' }],
        major: [{ required: true, message: '请输入专业', trigger: 'blur' }],
        age: [{ required: true, validator: validate_3, trigger: 'blur' }],
        validate_1: [{ required: true, validator: validate_1, trigger: "change" }],
        validate_2: [{ required: true, validator: validate_2, trigger: "change" }],
      },
      tableData: [],
      detailsInfo: {},
      education_files: [],// 学历材料
      job_files: [],// 职称证明
      reqObj: {
        teaching_studio_appraisal_id: null,
        teaching_studio_id: null,
      },
      listLoading: true, //表格Loading
      //职称
      stepArr: [],
      current_step: 0,
      infoData: {},
      check_log: [],
      currentStatus: null,
      current_2_Status: null,
    }
  },
  computed: {
    // 设置分数
    theSum() {
      let total = 0;
      this.tableData.forEach(item_1 => {
        item_1.children.forEach(item_2 => {
          total = mathjs.add(total, (Number(item_2.score_val) || 0));
        });
      });
      return total || 0;
    },
    // 自评分
    selfSum() {
      let total = 0;
      this.tableData.forEach(item_1 => {
        item_1.children.forEach(item_2 => {
          total = mathjs.add(total, (Number(item_2.self_score) || 0));
        });
      });
      return total || 0;
    },
  },

  components: {
    JxFileViewBox,
    JxStepBar,
  },

  methods: {
    setText(str) {
      let words = str
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&rdquo;/gi, "")
        .replace(/&ldquo;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
    openBianJi(item1, item2, item3) {
      this.dataId = item3.id
      let domAll = document.querySelectorAll(".proof_item_textarea_div");
      domAll.forEach(item => {
        if (item.getAttribute('data-pid') == item3.id) {
          this.contents = item.value
          this.dialogcontents = true
        }
      })
    },
    addcontents() {
      let domAll = document.querySelectorAll(".proof_item_textarea_div");
      domAll.forEach(item => {
        if (item.getAttribute('data-pid') == this.dataId) {
          item.value = this.contents
          // item.value = this.setText(this.contents)
          this.dialogcontents = false
        }
      })
    },
    closecontents() {
      this.dialogcontents = false
    },
    upDaochu() {
      //window.open('https://dckj.ks3-cn-guangzhou.ksyun.com/test/2023-08-15/9e13edf2-e722-4e54-a8e2-ecdcd2fbf9a8.zip')
      //return
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      this.$message.success("处理中，请勿刷新页面~")
      researchApi.jx_api_export_appraisal(tempOBj).then(res => {
        researchApi.jx_api_logs_filesList(tempOBj).then(ress => {
          if (ress.data.data.data.length == 0) return
          window.open(ress.data.data.data[0].url)
        }).catch(err => {
        })
      }).catch(err => {
      })
    },
    // 获取工作室审核状态
    get_info_studio_submit() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      researchApi.jx_api_TeachingStudioAppraisal_zb_detailList_post_examine_submit(tempOBj).then(res => {
        this.get_my_info();
      }).catch(err => {
      })
    },
    // 获取工作室审核状态
    get_info_studio_info() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      researchApi.jx_api_TeachingStudioAppraisal_zb_detailList_get_examine_st(tempOBj).then(res => {
        let resData = res.data.data;
        this.currentStatus = resData.button || null;
        this.current_2_Status = "提交";
        if (resData.examine && (resData.examine.examine > 0 && resData.examine.examine_status == 2)) {
          this.current_2_Status = "重新提交";
        };
        this.$parent.get_info_studio_info();
      }).catch(err => {
      })
    },
    // input排序
    get_input_sort(arr) {
      let oldArr = deepClone(arr);
      let new_list = [];
      let new_2_list = [];
      oldArr.forEach(item1 => {
        let idx = new_list.findIndex(item2 => {
          return item2.pid === item1.pid
        });
        if (idx === -1) {
          item1.sort = 1;
          new_list.push({
            pid: item1.pid,
            list: [item1],
          })
        } else {
          item1.sort = new_list[idx].list.length + 1;
          new_list[idx].list.push(item1)
        }
      });
      new_list.forEach(item => {
        new_2_list = [...new_2_list, ...item.list];
      });
      return new_2_list;
    },
    // 获取input的value
    get_input_all() {
      return new Promise((resolve, reject) => {
        let valArr = [];
        try {
          let domAll = document.querySelectorAll(".proof_item_input_div");
          domAll.forEach((item, index) => {
            // if (!isPureNumber(item.value)) {
            //   throw new Error('佐证材料输入框 仅支持数字整数');
            // };
            let tempObj = {
              pid: item.getAttribute('data-pid'),
              sort: index + 1,
              val: item.value,
            };
            valArr.push(tempObj);
          });
        } catch (e) {
          this.$message({
            message: e.message,
            type: "error",
          });
          return reject();
        };
        let sort_arr = this.get_input_sort(valArr);
        return resolve(sort_arr);
      });
    },
    // 获取textarea的value
    get_textarea_all() {
      return new Promise((resolve, reject) => {
        let valArr = [];
        try {
          let domAll = document.querySelectorAll(".proof_item_textarea_div");
          domAll.forEach((item, index) => {
            // if (!isPureNumber(item.value)) {
            //   throw new Error('佐证材料输入框 仅支持数字整数');
            // };
            let tempObj = {
              pid: item.getAttribute('data-pid'),
              sort: index + 1,
              val: item.value,
            };
            valArr.push(tempObj);
          });
        } catch (e) {
          this.$message({
            message: e.message,
            type: "error",
          });
          return reject();
        };
        let sort_arr = this.get_input_sort(valArr);
        return resolve(sort_arr);
      });
    },
    // 获取所有文件
    get_files_arr() {
      let tempArr = deepClone(this.tableData);
      let fileArr = [];
      tempArr.forEach((item_1, index_1) => {
        item_1.children.forEach((item_2, index_2) => {
          item_2.proof_list.forEach((item_3, index_3) => {
            if (item_3.cate == 1) {
              item_3.file_arr.forEach((item_4, index_4) => {
                let tempItem = deepClone(item_4);
                tempItem['pid'] = item_3.id;
                tempItem['val'] = tempItem.name || tempItem.val;
                tempItem['sort'] = index_4 + 1;
                fileArr.push(tempItem);
              });
            }
          });
        });
      });
      return fileArr;
    },
    // 获取所有自评分
    get_score_arr() {
      let tempArr = deepClone(this.tableData);
      let scoreArr = {};
      tempArr.forEach((item_1, index_1) => {
        item_1.children.forEach((item_2, index_2) => {
          let self_score = item_2.self_score == "-" ? 0 : item_2.self_score;
          this.$set(scoreArr, item_2.id, Number(self_score));
        });
      });
      return scoreArr;
    },
    // 保存下一步
    handleSaveNext: throttle(async function (num) {
      let valArr = [];
      let textArr = []
      try {
        valArr = await this.get_input_all();
      } catch (error) {
        return false;
      };
      try {
        textArr = await this.get_textarea_all();
      } catch (error) {
        return false;
      };
      let fileArr = this.get_files_arr();
      let scoreArr = this.get_score_arr();
      let tempObj_1 = {};
      tempObj_1['save_type'] = num;
      tempObj_1['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempObj_1['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      tempObj_1['score_list'] = scoreArr;
      tempObj_1['data'] = {
        files: fileArr,
        input_text: valArr,
        textarea_text: textArr,
      };
      if (num == 1) { this.save_btn_1_loading = true; }
      if (num == 2) { this.save_btn_2_loading = true; }
      console.log(tempObj_1);
      researchApi.jx_api_TeachingStudioAppraisal_goSubmitlInfoUser_post(tempObj_1).then(res => {
        this.$notify({
          title: '操作成功',
          type: 'success',
          duration: 2000,
          offset: 40,
        });
        if (num == 1) { this.save_btn_1_loading = false; }
        if (num == 2) {
          this.get_info_studio_submit();
          this.save_btn_2_loading = false;
        };
        this.get_my_info();
      }).catch(err => {
        if (num == 1) { this.save_btn_1_loading = false; }
        if (num == 2) { this.save_btn_2_loading = false; }
      })
    }),
    // 获取详情
    get_my_info() {
      this.get_info_step();
      this.get_info_log();
      this.get_info_detailList();
      this.get_info_studio_info();
    },
    // 获取日志
    get_info_log() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      researchApi.jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalExamine_examine_log(tempOBj).then(res => {
        let resData = res.data.data;
        this.check_log = resData || [];
      }).catch(err => {
      })
    },
    // 获取步骤条
    get_info_step() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['search_teaching_studio_id'] = this.reqObj.teaching_studio_id;
      researchApi.jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInf_get_show(tempOBj).then(res => {
        let resData = res.data.data;
        console.log(resData);
        this.stepArr = [];
        this.$forceUpdate();
        if (isObject(resData.steps)) {
          let tempArr = [];
          Object.values(resData.steps).forEach(v => { tempArr.push(v); });
          this.stepArr = tempArr;
        } else {
          this.stepArr = resData.steps;
        };
        this.stepArr.forEach((item, index) => {
          if (isObject(resData.examine) && (item.examine == resData.examine.examine)) {
            this.current_step = index + 1;
          };
          if (item.examine == 6 && item.step_status == 2) {
            this.current_step = 10;
          };
        });
        this.infoData = resData;
        this.$forceUpdate();
      }).catch(err => { })
    },
    // #话题解析#
    emotionParse_1_Event(item_2, item_3, index_3) {
      //   disabled = "disabled"  value=""
      return `<input class="proof_item_input_div" type="text" data-pid=${item_3.id} />`;
    },
    emotionParse_2_Event(item_2, item_3, index_3) {
      return `<textarea class="proof_item_textarea_div" data-pid=${item_3.id} />`;
    },
    // 指标详情
    get_info_detailList() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      this.listLoading = true;
      researchApi.jx_api_TeachingStudioAppraisal_zb_detailList_examine_log(tempOBj).then(res => {
        let resData = res.data.data;
        this.detailsInfo = resData.main_info || {};
        if (this.detailsInfo.hasOwnProperty('self_scale') && parseInt(this.detailsInfo.self_scale)) {
          this.$set(this.detailsInfo, "is_show_self", true);
        } else {
          this.$set(this.detailsInfo, "is_show_self", false);
        };
        let tempArr = resData.list || []
        tempArr.forEach((item_1, index_1) => {
          item_1.children.forEach((item_2, index_2) => {
            item_2.proof_list.forEach((item_3, index_3) => {
              if (item_3.cate == 3) {
                let tempStr = deepClone(item_3.description);
                item_3['description'] = "*" + tempStr.replace(/\#text#/gi, this.emotionParse_1_Event(item_2, item_3, index_3));
              };
              if (item_3.cate == 4) {
                let tempStr = deepClone(item_3.description);
                item_3['description'] = "*" + tempStr.replace(/\#textarea#/gi, this.emotionParse_2_Event(item_2, item_3, index_3));
              };
            });
          });
        });
        this.tableData = tempArr;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }).catch(err => {
        this.tableData = [];
        this.$nextTick(() => {
          this.listLoading = false;
        });
      })
    },
    // 获取金山云配置
    async _getAddress() {
      this.isUpOk = false;
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传学历
    async dcFile_1(data, item_3) {
      if (!data.upload) return;
      let jsyMd5 = data.upload.CompleteMultipartUploadResult.ETag;
      let res_1 = await this.$axios.get("/etagChangeId", {
        params: { etag: jsyMd5.slice(1, jsyMd5.length - 1), name: data.filename, }
      });
      let item_files = res_1.data.data;
      item_files['val'] = item_files.name;
      item_3.file_arr.push(item_files);
    },
    // 点击删除
    handleDelete(item_3, index) {
      this.$confirm('是否确定删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        item_3.file_arr.splice(index, 1);
      }).catch(err => { console.error(err) })
    },
    // 自评分 存在0的时候
    selfHasExist() {
      let is_ok = false;
      this.tableData.forEach(item_1 => {
        item_1.children.forEach(item_2 => {
          let self_score = Number(item_2.self_score) || 0;
          if (self_score <= 0) {
            is_ok = true;
          }
        });
      });
      return is_ok;
    },
    // 提交审核
    handleSubmit() {
      let res_1 = this.selfHasExist();
      let tips_str = "";
      if (this.detailsInfo.is_show_self && res_1) {
        tips_str = `<strong style="color:red">评分项存在0分，</strong><strong>提交后不可修改，</strong><strong>是否确认提交？</strong>`;
      } else {
        tips_str = `<strong>提交后不可修改，</strong><strong>是否确认提交？</strong>`;
      };
      this.$confirm(tips_str, '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleSaveNext(2)
      }).catch(err => { console.error(err) })
    },
    // 返回
    goPageBack() {
      this.$router.go(-1);
    },
  },

  destroyed() {
  },

  mounted() {
    this._getAddress();
    let route_query = this.$route.query;
    if (route_query && route_query.teaching_studio_appraisal_id) {
      this.$set(this.reqObj, "teaching_studio_appraisal_id", route_query.teaching_studio_appraisal_id);
    };
    if (route_query && route_query.teaching_studio_id) {
      this.$set(this.reqObj, "teaching_studio_id", route_query.teaching_studio_id);
    };
    this.get_my_info();

    let CheckTarget = document.getElementById('CheckTarget');
    CheckTarget.addEventListener('change', (e) => {
      e = e || window.event;
      let target = e.target;
      if (target.className == 'proof_item_input_div') {
        // target.value = target.value;
        target.setAttribute('value', target.value);
      }
    });

  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.CheckTarget {
  width: 100%;
}

.tips_msg_text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  color: rgba(254, 128, 52, 1);
}

.optiondiv_box {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;

  .optiondiv_btn {
    width: 160px;
    height: 48px;
    background: rgba(52, 137, 254, 1);
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    margin: 0 25px;
  }

  .optiondiv_1_btn {
    background-color: #1fae4e;
  }

  .optiondiv_2_btn {
    background-color: #ccc !important;
  }
}

.step_div_box {
  display: flex;
  align-items: center;
  flex: 1;
  padding-top: 26px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 30px;
}

.time_line_div {
  padding-top: 40px;
  padding-left: 20px;

  /deep/ .el-timeline-item__wrapper {
    top: -18px !important;
    padding-left: 34px;
  }

  /deep/ .el-timeline-item__timestamp {
    margin-top: 2px !important;

    &::before {
      content: "";
      width: 18px;
      height: 1px;
      position: absolute;
      left: 16px;
      top: 28px;
      border-top: 2px dashed #e4e7ed !important;
    }
  }

  /deep/ .el-timeline-item__tail {
    border-left: 2px dashed #e4e7ed !important;
  }

  /deep/ .el-timeline-item__node {
    left: -7px;
    background-color: #148dfb !important;
    width: 23px;
    height: 23px;
    background: rgba(20, 141, 251, 1);
    border: 4px solid rgba(190, 224, 254, 1);
    box-sizing: border-box;
  }

  /deep/ .el-timeline-item {
    padding-bottom: 10px;
  }

  .time_line_time {
    padding: 18px 30px;
    background: rgba(246, 248, 251, 1);
    border-radius: 10px;
  }

  .time_line_p {
    font-size: 16px;
    font-weight: bold;

    .time_span_1 {
      font-weight: normal;
      color: rgba(102, 102, 102, 1);
      margin-right: 20px;
    }

    .time_span_2 {
      color: rgba(51, 51, 51, 1);
    }

    .time_span_3 {
      color: #148dfb;
    }

    .time_span_4 {
      color: #1fae4e;
      margin-left: 10px;
    }

    .time_span_5 {
      color: #ff7878;
      margin-left: 10px;
    }

    .time_span_6 {
      color: #1fae4e;
    }
  }

  .time_line_remark {
    margin-top: 8px;
    line-height: 22px;
    font-size: 16px;
    font-weight: normal;
    color: rgba(51, 51, 51, 1);
  }
}

.table_div_box {
  width: 100%;

  .table_box {
    border: 1px solid #dfe6ec;
    border-right: none;
    border-bottom: none;
    box-sizing: border-box;

    .table_title_div {
      display: flex;
      background-color: #e6edfd;
    }

    .table_title_item {
      height: 60px;
      color: #333;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      border-right: 1px solid #dfe6ec;
      border-bottom: 1px solid #dfe6ec;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .table_title_box {
      display: flex;
      flex: 1;
    }

    .table_content_box {
      width: 100%;
    }

    .table_content_item {
      width: 100%;
      display: flex;
    }

    .table_1_children {
      border-right: 1px solid #dfe6ec;
      border-bottom: 1px solid #dfe6ec;
    }

    .table_2_box_div {
      flex: 1;
    }

    .table_2_children {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #dfe6ec;
    }

    .table_2_add_box {
      border-right: 1px solid #dfe6ec;
      padding: 5px;
    }

    .table_2_item {
      border-right: 1px solid #dfe6ec;
    }

    .table_input_div {
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 5px;
    }

    .table_files_div {
      width: 100% !important;
    }

    .table_title_1_span {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding: 4px;
      text-align: center;
    }

    .table_title_2_span {
      font-size: 16px;
      color: #333333;
      padding: 4px;
      text-align: center;
    }

    .table_title_3_span {
      font-size: 16px;
      color: #333333;
      padding: 4px;
    }

    .table_1_div_item {
      //   width: 15% !important;
      //   flex: 0 0 15% !important;
      width: 126px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
    }

    .table_2_div_item {
      //   width: 18% !important;
      width: 130px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
    }

    .table_3_div_item {
      //   width: 30% !important;
      width: 300px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 22px 10px;
    }

    .table_4_div_item {
      width: 140px !important;
      //   min-width: 140px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    .table_6_div_item {
      //   width: 140px !important;
      //   min-width: 140px !important;
      width: 100px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
    }

    .table_5_div_item {
      //   width: 40% !important;
      flex: 1;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      align-items: center;
    }
  }

  .add_1_btn {
    margin-top: 10px;
  }

  .table_3_add_box {
    width: 100%;
    margin-bottom: 0px;
  }

  .proof_item_proof {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    line-height: 0;

    &.active {
      margin-bottom: 0;
    }
  }

  .proof_item_file {
    .proof_item_add_div {
      display: flex;

      .proof_item_add_name {
        flex: 1;
        font-size: 16px;
        line-height: 22px;
        color: rgba(51, 51, 51, 1);
        padding-right: 10px;
        padding-top: 4px;
      }

      .proof_item_add_btn {
        width: 70px;
        height: 30px;
        background: rgba(255, 141, 40, 1);
        border-radius: 8px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
      }
    }

    .proof_item_add_list {
      padding-left: 12px;

      .files_item {
        display: flex;
        align-items: center;
        line-height: 24px;

        span {
          flex: 1;
          font-size: 14px;
          font-weight: normal;
          color: #148dfb;
          text-decoration: underline;
          text-underline-offset: 4px;
          cursor: default;
        }

        .del_btn {
          margin-left: 20px;
          font-size: 14px;
          font-weight: normal;
          color: rgba(255, 67, 67, 1);
          cursor: pointer;
        }
      }
    }
  }

  .proof_item_html {
    font-size: 16px;
    line-height: 22px;
    color: rgba(51, 51, 51, 1);
  }

  .proof_item_input_box {
    font-size: 16px;
    line-height: 22px;
    color: rgba(51, 51, 51, 1);
    display: flex;
    align-items: center;
  }

  .textbox {
    display: flex;
  }

  .proof_item_text_box {
    width: 90%;
    font-size: 16px;
    line-height: 22px;
    color: rgba(51, 51, 51, 1);
    display: flex;
    align-items: center;
  }
}

/deep/.proof_item_input_div {
  height: 28px;
  line-height: 28px;
  width: 50px;
  margin: 0 5px;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid rgba(204, 204, 204, 1);
  color: #333;
  background-color: #fff;
  border-radius: 4px;
}

/deep/.proof_item_textarea_div {
  resize: vertical;
  width: 100%;
  height: 80px;
  margin: 0 5px;
  padding: 6px;
  border: 1px solid rgba(204, 204, 204, 1);
  color: #333;
  background-color: #fff;
  border-radius: 4px;
}

.upload_div_box {
  position: relative;

  /deep/ .el-progress-circle {
    width: 30px !important;
    height: 30px !important;
  }

  /deep/ .el-progress {
    left: 10px;
  }

  .avatar-uploader {}
}

.option_box_btn {
  width: 100px;
  height: 36px;
  line-height: 36px;
  background: rgba(52, 137, 254, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  margin-right: 0px;
  margin-left: 20px;
  cursor: pointer;
}
</style>