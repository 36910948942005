import * as math from 'mathjs';
export default {
  // 加
  add(num1, num2) {
    let total = math.format(math.chain(math.bignumber(num1)).add(math.bignumber(num2)).done());
    return Number(total);
  },
  // 乘
  multiply(num1, num2) {
    let total = math.format(math.chain(math.bignumber(num1)).multiply(math.bignumber(num2)).done());
    return Number(total);
  },
  // 减
  subtract(num1, num2) {
    let total = math.format(math.chain(math.bignumber(num2)).subtract(math.bignumber(num1)).done());
    return Number(total);
  },
  // 除
  divide(num1, num2) {
    let total = math.format(math.chain(math.bignumber(num1)).divide(math.bignumber(num2)).done());
    return Number(total);
  },
};