<template>
  <div class="public_main_bgc">
    <div class="public_main_div public_css_module performanceDetails">

      <div class="bread_top_nav_div">
        <span class="bread_top_nav_text">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>四名工作室首页</el-breadcrumb-item>
          <el-breadcrumb-item>工作室绩效考核</el-breadcrumb-item>
          <el-breadcrumb-item>
            <span class="bread_top_nav_item">详情</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="performance_main">
        <div class="title_page">
          <div class="icon_back" @click="goPageBack"> <i class="el-icon-arrow-left"></i> </div>
          <span>工作室绩效考核</span>
        </div>

        <div class="performance_div">

          <div class="userInfo_div">
            <div class="userInfo_left">
              <p class="ellipsis_1"> {{ infoData.title || "-" }} </p>
              <div class="msg_info_div">
                <span class="msg_info_item">考核周期：{{ infoData.show_title }}</span>
                <span class="msg_info_item">考核对象：{{ infoData.host_type_str }}</span>
                <span class="msg_info_item" v-if="infoData.start_time_stamp" style="margin-right: 0px">考核开展时间：{{
                  dateTime(infoData.start_time_stamp) }} 至
                  {{ dateTime(infoData.end_time_stamp) }}</span>
              </div>
            </div>
            <el-button class="userInfo_right" type="primary" @click="handlePage">工作室首页</el-button>
          </div>

          <div class="navList">
            <div class="nav_item" :class="{ 'active': item.id == nav_active }" v-for="(item, index) in nav_arr"
              :key="index" @click="handleTable(item)">
              {{ item.name }}
            </div>
          </div>

          <!-- 工作室基本信息 -->
          <div v-if="nav_active == 1" class="essential_info_div" v-loading="listLoading">

            <template v-if="infoDetails.hasOwnProperty('teaching_studio_info')">
              <div class="page_span_title">工作室基本信息</div>
              <div class="page_span_div">
                <div class="page_item_div" v-if="infoDetails.teaching_studio_info.hasOwnProperty('teaching_studio_name')">
                  工作室名称：{{ infoDetails.teaching_studio_info.teaching_studio_name }}
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_info.hasOwnProperty('stage_subject')">
                  学段学科：{{ infoDetails.teaching_studio_info.stage_subject }}
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_info.hasOwnProperty('org')">
                  设立单位：{{ infoDetails.teaching_studio_info.org || '-' }}
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_info.hasOwnProperty('member_count2')">
                  工作室管理员：{{ infoDetails.teaching_studio_info.member_count2 }}人
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_info.hasOwnProperty('member_count1')">
                  骨干成员：{{ infoDetails.teaching_studio_info.member_count1 }}人
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_info.hasOwnProperty('member_count3')">
                  网络成员：{{ infoDetails.teaching_studio_info.member_count3 }}人
                </div>
              </div>
            </template>

            <template v-if="infoDetails.hasOwnProperty('teaching_studio_statistics')">
              <div class="page_span_title">工作室数据统计</div>
              <div class="page_span_div">
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('user_count')">
                  工作室成员：{{ infoDetails.teaching_studio_statistics.user_count }}人
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('activity_count')">
                  教研活动：{{ infoDetails.teaching_studio_statistics.activity_count }}个
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('gambit_count')">
                  话题研讨：{{ infoDetails.teaching_studio_statistics.gambit_count }}个
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('notice_count')">
                  通知公告：{{ infoDetails.teaching_studio_statistics.notice_count }}条
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('resource_count')">
                  研修资源：{{ infoDetails.teaching_studio_statistics.resource_count }}份
                </div>
                <div class="page_item_div"
                  v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('certificate_count')">
                  荣誉证书：{{ infoDetails.teaching_studio_statistics.certificate_count }}本
                </div>
                <div class="page_item_div"
                  v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('course_live_count')">
                  四名讲堂：{{ infoDetails.teaching_studio_statistics.course_live_count }}个
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('article_count')">
                  教师文章：{{ infoDetails.teaching_studio_statistics.article_count }}篇
                </div>
                <div class="page_item_div" v-if="infoDetails.teaching_studio_statistics.hasOwnProperty('view_count')">
                  总访问量：{{ infoDetails.teaching_studio_statistics.view_count }}人次
                </div>
              </div>
            </template>

            <el-form ref="FormTempData" :model="FormTempData" :rules="rules1" label-position="right" label-width="150px"
              style="width: 100%;">
              <template v-if="infoDetails.hasOwnProperty('teaching_studio_user')">
                <div class="page_span_title">工作室主持人</div>
                <div class="page_span_div active">
                  <div class="page_item_div" v-if="infoDetails.teaching_studio_user.hasOwnProperty('realname')">
                    姓名：{{ infoDetails.teaching_studio_user.realname }}
                  </div>

                  <div class="page_item_div page_item_1_div" v-if="FormTempData.hasOwnProperty('education')"
                    :class="{ 'page_item_1_active': ![1, 2].includes(currentStatus) }">
                    <el-form-item label="学历：" prop="education" class="page_item_2_div"
                      v-if="![1, 2].includes(currentStatus)">
                      <el-select disabled clearable filterable v-model="FormTempData.education" placeholder="请选择学历"
                        style="width: 200px;">
                        <el-option v-for="item in optiones3" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="学历：" prop="education" v-if="[1, 2].includes(currentStatus)">
                      <el-select clearable filterable v-model="FormTempData.education" placeholder="请选择学历"
                        style="width: 200px;">
                        <el-option v-for="item in optiones3" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>

                  <div class="page_item_div page_item_1_div" v-if="FormTempData.hasOwnProperty('major')"
                    :class="{ 'page_item_1_active': ![1, 2].includes(currentStatus) }">
                    <el-form-item label="专业：" prop="major" class="page_item_2_div" v-if="![1, 2].includes(currentStatus)">
                      <el-input disabled v-model="FormTempData.major" placeholder="请输入专业" style="width: 200px;" />
                    </el-form-item>
                    <el-form-item label="专业：" prop="major" v-if="[1, 2].includes(currentStatus)">
                      <el-input v-model="FormTempData.major" placeholder="请输入专业" style="width: 200px;" />
                    </el-form-item>
                  </div>

                  <div class="page_item_div page_item_1_div" v-if="FormTempData.hasOwnProperty('age')"
                    :class="{ 'page_item_1_active': ![1, 2].includes(currentStatus) }">
                    <el-form-item label="年龄：" prop="age" class="page_item_2_div" v-if="![1, 2].includes(currentStatus)">
                      <el-input-number disabled size="small" v-model="FormTempData.age" :step-strictly="true" :min="18"
                        :max="200"> </el-input-number>
                    </el-form-item>
                    <el-form-item label="年龄：" prop="age" v-if="[1, 2].includes(currentStatus)">
                      <el-input-number size="small" v-model="FormTempData.age" :step-strictly="true" :min="18" :max="200">
                      </el-input-number>
                    </el-form-item>
                  </div>

                  <div class="page_item_div page_item_1_div" v-if="FormTempData.hasOwnProperty('ranks')"
                    :class="{ 'page_item_1_active': ![1, 2].includes(currentStatus) }">
                    <el-form-item label="职称：" prop="ranks" class="page_item_2_div" v-if="![1, 2].includes(currentStatus)">
                      <el-select disabled clearable filterable v-model="FormTempData.ranks" placeholder="请选择职称"
                        style="width: 200px;">
                        <el-option v-for="item in optiones2" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="职称：" prop="ranks" v-if="[1, 2].includes(currentStatus)">
                      <el-select clearable filterable v-model="FormTempData.ranks" placeholder="请选择职称"
                        style="width: 200px;">
                        <el-option v-for="item in optiones2" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>

                  <div class="page_item_div" v-if="infoDetails.teaching_studio_user.hasOwnProperty('org')">
                    所属单位：{{ infoDetails.teaching_studio_user.org }}
                  </div>
                </div>
              </template>

              <template
                v-if="infoDetails.hasOwnProperty('teaching_studio_member') && infoDetails.teaching_studio_member.hasOwnProperty('field')">
                <div class=" page_span_title">工作室成员信息</div>
                <div class="page_span_table">
                  <el-table :data="infoDetails.teaching_studio_member.data || []" fit
                    :header-cell-style="{ background: '#eef2fb', 'font-weight': 'bold', 'font-size': '16px', 'color': '#333333', }"
                    highlight-current-row style="width: 100%;">

                    <el-table-column label="身份" align="center" v-if="infoDetails.teaching_studio_member.field.idnum == 1">
                      <template slot-scope="{row}">
                        <span>{{ row.idnum }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="姓名" align="center"
                      v-if="infoDetails.teaching_studio_member.field.realname == 1">
                      <template slot-scope="{row}">
                        <span>{{ row.realname }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="学科/领域" align="center"
                      v-if="infoDetails.teaching_studio_member.field.subject == 1">
                      <template slot-scope="{row}">
                        <span>{{ row.subject }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="职称" align="center" v-if="infoDetails.teaching_studio_member.field.ranks == 1">
                      <template slot-scope="{row}">
                        <span>{{ row.ranks }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="所属单位" align="center" v-if="infoDetails.teaching_studio_member.field.org == 1">
                      <template slot-scope="{row}">
                        <span>{{ row.org }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="联系方式" align="center"
                      v-if="infoDetails.teaching_studio_member.field.contact == 1">
                      <template slot-scope="{row}">
                        <span>{{ row.contact }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>

              <template v-if="infoDetails.hasOwnProperty('teaching_studio_user_file')">
                <div class="page_span_title">主持人身份材料上传</div>
                <div class="prove_list_div">
                  <div
                    v-if="infoDetails.hasOwnProperty('teaching_studio_user_file') && infoDetails.teaching_studio_user_file.hasOwnProperty('education')"
                    class="prove_item_box">
                    <div class="prove_item_left">
                      <span v-if="[1, 2].includes(currentStatus)"> * </span>
                      学历证明
                    </div>
                    <div class="prove_item_right">
                      <el-form-item label="" prop="validate_1" label-width="0px">
                        <div v-for="(files_item, files_index) in education_files" class="files_item" :key="files_index">
                          <JxFileViewBox :fileArr="education_files" :fileIndex="files_index" DialogTitle="学历证明">
                            <span class="ellipsis_1"> {{ files_index + 1 > 9 ? files_index + 1 : '0' + (files_index + 1)
                            }}、{{ files_item.name }} </span>
                          </JxFileViewBox>
                          <div v-if="[1, 2].includes(currentStatus)" class="del_btn"
                            @click="handleDelete(files_item, files_index, 1)">删除</div>
                        </div>
                        <div v-if="[1, 2].includes(currentStatus)" class="upload_div_box">
                          <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                            dcdom="ceshi99" :dcConfig="dcConfig" @dc-success="dcFile_1($event, 1)"
                            @dc-progress="dcProgressTrue = false"
                            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.gif,.jpg,.jpeg,.png," :filesSizeMax="50">
                            <el-button class="add_file_btn" slot="trigger" size="small" type="warning">上传材料 +</el-button>
                          </dc-upload>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                  <div
                    v-if="infoDetails.hasOwnProperty('teaching_studio_user_file') && infoDetails.teaching_studio_user_file.hasOwnProperty('ranks')"
                    class="prove_item_box">
                    <div class="prove_item_left">
                      <span v-if="[1, 2].includes(currentStatus)"> * </span>
                      职称证明
                    </div>
                    <div class="prove_item_right">
                      <el-form-item label="" prop="validate_2" label-width="0px">
                        <div v-for="(files_item, files_index) in job_files" class="files_item" :key="files_index">
                          <JxFileViewBox :fileArr="job_files" :fileIndex="files_index" DialogTitle="职称证明">
                            <span class="ellipsis_1"> {{ files_index + 1 > 9 ? files_index + 1 : '0' + (files_index + 1)
                            }}、{{ files_item.name }} </span>
                          </JxFileViewBox>
                          <div v-if="[1, 2].includes(currentStatus)" class="del_btn"
                            @click="handleDelete(files_item, files_index, 2)">删除</div>
                        </div>
                        <div v-if="[1, 2].includes(currentStatus)" class="upload_div_box">
                          <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false"
                            dcdom="ceshi91" :dcConfig="dcConfig" @dc-success="dcFile_1($event, 2)"
                            @dc-progress="dcProgressTrue = false"
                            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.gif,.jpg,.jpeg,.png," :filesSizeMax="50">
                            <el-button class="add_file_btn" slot="trigger" size="small" type="warning">上传材料 +</el-button>
                          </dc-upload>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div v-if="[1, 2].includes(currentStatus)" class="tips_msg_text">备注：材料上传支持Word/PDF/JPG/PNG等格式，每份材料不能超过50M。
                </div>
              </template>

            </el-form>

            <div v-if="[1, 2].includes(currentStatus)" class="optiondiv_box">
              <el-button :loading="save_btn_1_loading" class="optiondiv_btn" type="primary"
                @click="handleSaveNext(1)">保存</el-button>
              <el-button :loading="save_btn_2_loading" class="optiondiv_btn" type="primary"
                @click="handleSaveNext(2)">保存至下一步</el-button>
            </div>
          </div>

          <!-- 工作室各指标考核 -->
          <div v-if="nav_active == 2" class="essential_info_div">
            <CheckTarget :examineLi="infoData.examine.examine ? infoData.examine : {}"></CheckTarget>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import NewFileUploadModule from "@/components/uploadResource/NewFileUploadModule/NewFileUploadModule.vue";
import { debounce, throttle } from "@/utils/utils.js";
import { deepClone, isObject } from "@/utils/utils.js";
import * as researchApi from '@/api/researchApi.js';
import JxFileViewBox from "./jx_fileView_box.vue";
import CheckTarget from "./CheckTarget.vue";

export default {
  name: "asfadfad",
  data() {
    const validate_1 = (rule, value, callback) => {
      if (!this.education_files.length) {
        callback(new Error('请至少选择一个材料文件'));
      } else {
        callback();
      };
    };
    const validate_2 = (rule, value, callback) => {
      if (!this.job_files.length) {
        callback(new Error('请至少选择一个材料文件'));
      } else {
        callback();
      };
    };
    const validate_3 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('年龄不能为空'));
      };
      if (value > 200 || value < 18) {
        callback(new Error('18-100区间范围'));
      } else {
        callback();
      }
    };
    return {
      save_btn_1_loading: false, // 保存按钮loading
      save_btn_2_loading: false, // 保存按钮loading
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      nav_active: 1,
      nav_arr: [
        { name: '工作室基本信息', id: 1, },
        { name: '工作室各指标考核', id: 2, },
      ],
      FormTempData: {
        teaching_studio_id: "",
        teaching_studio_appraisal_id: "",
        education: "",
        major: "",
        age: "",
        ranks: "",
        education_file_ids: "",
        ranks_file_ids: "",
      },
      rules1: {
        education: [{ required: true, message: '请选择学历', trigger: 'change' }],
        ranks: [{ required: true, message: '请选择职称', trigger: 'change' }],
        major: [{ required: true, message: '请输入专业', trigger: 'blur' }],
        age: [{ required: true, validator: validate_3, trigger: 'blur' }],
        validate_1: [{ required: true, validator: validate_1, trigger: "change" }],
        validate_2: [{ required: true, validator: validate_2, trigger: "change" }],
      },
      tableData: [],
      education_files: [],// 学历材料
      job_files: [],// 职称证明
      reqObj: {
        teaching_studio_appraisal_id: null,
        teaching_studio_id: null,
      },
      listLoading: true, //表格Loading
      infoDetails: {},
      //职称
      optiones2: [],
      optiones3: [],
      infoData: {},
      currentStatus: null,
      current_2_Status: "-",
    }
  },
  computed: {},

  components: {
    NewFileUploadModule,
    JxFileViewBox,
    CheckTarget,
  },

  methods: {
    dateTime(now) {
      var date = new Date(now * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '/';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      return Y + M + D;
    },
    // 获取工作室审核状态
    get_info_studio_info() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['teaching_studio_id'] = this.reqObj.teaching_studio_id;
      researchApi.jx_api_TeachingStudioAppraisal_zb_detailList_get_examine_st(tempOBj).then(res => {
        let resData = res.data.data;
        this.currentStatus = resData.button || null;
      }).catch(err => {
      })
    },
    // 获取步骤条
    get_info_step() {
      let tempOBj = {};
      tempOBj['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
      tempOBj['search_teaching_studio_id'] = this.reqObj.teaching_studio_id;
      researchApi.jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInf_get_show(tempOBj).then(res => {
        let resData = res.data.data;
        this.infoData = resData;
      }).catch(err => {
      })
    },
    // 获取职称
    async getProfessional_2() {
      let res = await this.$axios.get("/index/listTypeDictionary", { params: { type: 14 } });
      this.optiones2 = res.data.data || [];
    },
    // 获取学历
    async getProfessional_3() {
      let res = await this.$axios.get("/education");
      this.optiones3 = res.data.data;
    },
    // 获取详情
    get_my_info() {
      this.get_info_step();
      this.get_info_studio_info();
      this.listLoading = true;
      researchApi.jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInfoUser_show(this.reqObj).then(res => {
        let resData = res.data.data;
        this.infoDetails = resData;
        this.FormTempData = resData.teaching_studio_user || {};
        if (this.FormTempData.hasOwnProperty('education')) {
          if (isObject(this.FormTempData.education)) {
            this.$set(this.FormTempData, "education", this.FormTempData.education.id);
          }
        };
        if (this.FormTempData.hasOwnProperty('ranks')) {
          if (isObject(this.FormTempData.ranks)) {
            this.$set(this.FormTempData, "ranks", this.FormTempData.ranks.id);
          }
        };
        if (this.infoDetails.hasOwnProperty('teaching_studio_user_file') && this.infoDetails.teaching_studio_user_file.hasOwnProperty('education')) {
          this.education_files = this.infoDetails.teaching_studio_user_file.education.data || [];
        };
        if (this.infoDetails.hasOwnProperty('teaching_studio_user_file') && this.infoDetails.teaching_studio_user_file.hasOwnProperty('ranks')) {
          this.job_files = this.infoDetails.teaching_studio_user_file.ranks.data || [];
        };
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }).catch(err => {
        this.$nextTick(() => {
          this.listLoading = false;
        });
      })
    },
    // 保存下一步
    handleSaveNext: throttle(function (num) {
      this.$refs['FormTempData'].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "有必填项未填写",
            type: 'error',
            duration: 2 * 1000
          });
          return false
        };
        let tempObj_1 = deepClone(this.FormTempData);
        tempObj_1['teaching_studio_appraisal_id'] = this.reqObj.teaching_studio_appraisal_id;
        tempObj_1['teaching_studio_id'] = this.reqObj.teaching_studio_id;
        if (this.education_files.length) {
          let education_files = this.education_files.map(item => { return item.id; }).toString();
          tempObj_1['education_file_ids'] = education_files;
        };
        if (this.job_files.length) {
          let ranks_file_ids = this.job_files.map(item => { return item.id; }).toString();
          tempObj_1['ranks_file_ids'] = ranks_file_ids;
        };
        if (num == 1) { this.save_btn_1_loading = true; }
        if (num == 2) { this.save_btn_2_loading = true; }
        researchApi.jx_api_TeachingStudioAppraisal_TeachingStudioAppraisalInfoUser_post(tempObj_1).then(res => {
          this.$notify({
            title: '操作成功',
            type: 'success',
            duration: 2000,
            offset: 40,
          });
          if (num == 1) { this.save_btn_1_loading = false; }
          if (num == 2) {
            this.save_btn_2_loading = false;
            this.nav_active = 2;
            window.scrollTo(0, 0);
          };
          this.get_my_info();
        }).catch(err => {
          if (num == 1) { this.save_btn_1_loading = false; }
          if (num == 2) { this.save_btn_2_loading = false; }
        })
      })
    }),
    // 点击删除
    handleDelete(item, index, num) {
      this.$confirm('是否确定删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (num == 1) {
          this.education_files.splice(index, 1);
        };
        if (num == 2) {
          this.job_files.splice(index, 1);
        };
      }).catch(err => { console.error(err) })
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传学历
    async dcFile_1(data, num) {
      if (!data.upload) return;
      let jsyMd5 = data.upload.CompleteMultipartUploadResult.ETag;
      let res_1 = await this.$axios.get("/etagChangeId", {
        params: { etag: jsyMd5.slice(1, jsyMd5.length - 1), name: data.filename, }
      });
      let item_files = res_1.data.data;
      if (num == 1) {
        this.education_files.push(item_files);
        this.$refs['FormTempData'].validateField('validate_1'); //单独校验
      };
      if (num == 2) {
        this.job_files.push(item_files);
        this.$refs['FormTempData'].validateField('validate_2'); //单独校验
      };
    },
    // tab切换
    handleTable(item) {
      this.nav_active = item.id;
    },
    // 返回
    goPageBack() {
      this.$router.go(-1);
    },
    // 工作室首页
    handlePage() {
      this.$router.replace({
        path: "/TeachLectureIndex",
        query: {
          id: this.reqObj.teaching_studio_id,
          teaching_studio_id: this.reqObj.teaching_studio_id,
        },
      });
    },
  },

  destroyed() {
  },

  mounted() {
    this._getAddress();
    let route_query = this.$route.query;
    if (route_query && route_query.teaching_studio_appraisal_id) {
      this.$set(this.reqObj, "teaching_studio_appraisal_id", route_query.teaching_studio_appraisal_id);
    };
    if (route_query && route_query.teaching_studio_id) {
      this.$set(this.reqObj, "teaching_studio_id", route_query.teaching_studio_id);
    };
    this.get_my_info();
    this.getProfessional_2();
    this.getProfessional_3();
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.performanceDetails {
  padding-bottom: 30px;
}

.bread_top_nav_div {
  width: 100%;
  margin: 0px;
  padding: 0px;
  line-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;

  .bread_top_nav_text {
    font-size: 14px;
    color: #666;
  }

  .bread_top_nav_item {
    color: #ff8910 !important;
  }
}

.performance_main {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.title_page {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #f0f0f0;

  .icon_back {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }
}

.performance_div {
  width: 100%;
  padding: 30px;
  min-height: 800px;
}

.essential_info_div {
  width: 100%;
}

.userInfo_div {
  width: 100%;
  height: 100px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  background-image: url("~@/assets/badfad.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding-left: 38px;
  padding-right: 40px;

  .userInfo_left {
    flex: 1;

    >P {
      font-size: 24px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }

    .msg_info_div {
      padding-right: 30px;
      display: flex;
      font-size: 16px;
      margin-top: 10px;
      font-weight: normal;
      color: rgba(51, 51, 51, 1);

      .msg_info_item {
        margin-right: 110px;
      }
    }
  }

  .userInfo_right {
    flex: 0 0 160px;
    width: 160px;
    height: 46px;
    background: linear-gradient(0deg,
        rgba(1, 139, 253, 1) 0%,
        rgba(19, 196, 255, 1) 100%);
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navList {
  margin-top: 32px;
  height: 50px;
  border-bottom: 1px solid #ccc;
  display: flex;

  .nav_item {
    height: 100%;
    padding: 0 28px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #ccc;
    border-bottom: 0px solid #ccc;
    cursor: pointer;

    &.active {
      background-color: #3489fe;
      border-color: #3489fe;
      color: #fff;
      font-weight: bold;
    }
  }
}

.page_span_title {
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  color: rgba(102, 102, 102, 1);
  margin-top: 30px;
}

.page_span_div {
  width: 100%;
  margin-top: 20px;
  background: rgba(248, 250, 255, 1);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;

  &.active {
    padding: 20px 0;
    padding-bottom: 10px;
  }

  .page_item_div {
    // border: 1px solid #000;
    width: 33%;
    height: 40px;
    line-height: 40px;
    padding-left: 100px;
    font-size: 16px;
    font-weight: normal;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .page_item_text {
    font-size: 16px;
    font-weight: normal;
    color: rgba(51, 51, 51, 1);
    line-height: 40px;
  }

  .page_item_1_div {
    padding-left: 0;
    margin-bottom: 20px;
  }

  /deep/ .el-form-item__label {
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    color: rgba(51, 51, 51, 1);
  }
}

.page_item_1_active {
  margin-bottom: 0 !important;
}

.page_item_2_div {
  /deep/.el-form-item__label {
    line-height: 38px !important;

    &::before {
      visibility: hidden;
    }
  }

  /deep/.el-input__inner {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: #333333 !important;
    font-size: 16px !important;
    padding-left: 0px !important;
    text-align: left !important;
  }

  /deep/.el-input__suffix {
    display: none;
  }

  /deep/.el-input-number__decrease {
    display: none;
  }

  /deep/.el-input-number__increase {
    display: none;
  }
}

.page_span_table {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  margin-top: 20px;
  border: 1px solid #ebeef5;
  border-bottom: none;
}

.prove_list_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .prove_item_box {
    width: 48%;
    min-height: 100px;
    border: 1px solid rgba(221, 221, 221, 1);
    border-radius: 10px;
    display: flex;
    overflow: hidden;
  }

  .prove_item_left {
    flex: 0 0 170px;
    background-color: #eef2fb;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    border-radius: 10px;

    span {
      margin-right: 4px;
      color: rgba(255, 65, 65, 1);
    }
  }

  .prove_item_right {
    flex: 1;
    padding-left: 18px;
    padding-right: 30px;
    padding-top: 18px;

    .files_item {
      display: flex;
      align-items: center;
      line-height: 30px;

      span {
        flex: 1;
        font-size: 16px;
        font-weight: normal;
        color: #148dfb;
        text-decoration: underline;
        text-underline-offset: 4px;
        cursor: default;
      }

      .del_btn {
        margin-left: 20px;
        font-size: 16px;
        font-weight: normal;
        color: rgba(255, 67, 67, 1);
        cursor: pointer;
      }
    }
  }
}

.upload_div_box {
  position: relative;

  /deep/ .el-progress-circle {
    width: 40px !important;
    height: 40px !important;
  }

  /deep/ .el-progress {
    left: 150px;
  }

  .avatar-uploader {
    margin-top: 10px;
  }

  .add_file_btn {
    width: 120px;
    height: 40px;
    background: rgba(255, 141, 19, 1);
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
    font-weight: normal;
    padding: 0;
    color: rgba(255, 255, 255, 1);
    margin-right: auto;
  }
}

.tips_msg_text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  color: rgba(254, 128, 52, 1);
}

.optiondiv_box {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  .optiondiv_btn {
    width: 160px;
    height: 48px;
    background: rgba(52, 137, 254, 1);
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    margin: 0 25px;
  }
}
</style>