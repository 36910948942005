<template>
  <!-- 步骤条组件 -->
  <div class="step_box_min">
    <el-steps :active="currentIndex" align-center>
      <template v-for="(item_2, index_2) in stepArr">

        <!-- 空心 -->
        <el-step v-if="item_2.step_status==1" :key="index_2 + 'c'">
          <span slot="title">{{item_2.str}}</span>
          <span slot="description"></span>
        </el-step>

        <!-- 通过 勾-->
        <el-step v-if="item_2.step_status==2" status="" :key="index_2 + 'a'" class="step_div_2_item">
          <span slot="title">{{item_2.str}}</span>
          <span slot="icon" class="el-icon-success"></span>
        </el-step>

        <!-- 驳回 打叉-->
        <el-step v-if="item_2.step_status==3" status="error" :key="index_2 + 'b'">
          <span slot="title">{{item_2.str}}</span>
          <span v-if="item_2.examine==1" slot="description">请重新提交材料</span>
          <span v-else slot="description">驳回</span>
        </el-step>

        <!-- 前面有驳回 半圆-->
        <el-step v-if="item_2.step_status==4" status="finish" :key="index_2 + 'd'" class="step_div_item">
          <span slot="title">{{item_2.str}}</span>
          <span v-if="item_2.examine==1" slot="description">请重新提交材料</span>
        </el-step>

      </template>
      <!-- <el-step>
      <span slot="title">材料复审</span>
      <span slot="description"></span>
    </el-step> -->
    </el-steps>
  </div>
</template>

<script>
export default {
  props: {
    stepArr: {
      type: [Array],
      default: [],
    },
    currentIndex: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  computed: {},

  components: {},

  methods: {
  },

  destroyed() {
  },

  mounted() {
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.step_box_min {
  width: 100%;
  padding-top: 16px;
  /deep/ .el-step__icon {
    font-size: 28px;
  }
  /deep/ .el-step__title {
    font-size: 16px;
    line-height: 18px;
    margin-top: 18px;
  }
  /deep/ .el-step__icon div.el-step__icon-inner {
    visibility: hidden;
  }
  /deep/ .el-step__head.is-finish {
    color: #1fae4e;
    border-color: #1fae4e;
  }
  /deep/ .el-step__title.is-finish {
    color: #333333;
  }

  /deep/ .el-step__head.is-error {
    color: #ff7878;
    border-color: #ff7878;
  }
  /deep/ .el-step__head.is-error {
    .el-step__icon-inner {
      font-size: 28px;
      font-weight: normal;
      transform: translateY(0);
    }
    .el-step__icon.is-text {
      font-weight: normal;
      .el-icon-close:before {
        content: "\e79d" !important;
      }
    }
  }
  /deep/ .el-step__title.is-error {
    color: #ff7878;
    // color: #333333;
  }

  /deep/ .el-step__head.is-wait {
    color: #a9eec0;
    border-color: #a9eec0;
  }
  /deep/ .el-step__title.is-wait {
    color: #999;
  }

  /deep/ .el-step__title.is-process {
    color: #333;
    font-size: 15px;
    font-weight: bold;
  }

  /deep/ .el-step__description {
    padding: 0;
    font-size: 14px;
    margin-top: 18px;
    color: #ff7878;
    text-align: center;
  }
  /deep/ .step_div_item {
    .el-step__icon {
      background-color: #1fae4e;
      border: 2px solid rgba(169, 238, 192, 1);
    }
  }
  .step_div_2_item {
    /deep/.el-step__icon {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(169, 238, 192, 1);
      font-size: 29px;
      margin-top: -4px;
    }
  }
}
</style>