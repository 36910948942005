import { render, staticRenderFns } from "./jx_fileView_box.vue?vue&type=template&id=630e081e&scoped=true"
import script from "./jx_fileView_box.vue?vue&type=script&lang=js"
export * from "./jx_fileView_box.vue?vue&type=script&lang=js"
import style0 from "./jx_fileView_box.vue?vue&type=style&index=0&id=630e081e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630e081e",
  null
  
)

export default component.exports